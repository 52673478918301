import axios from "axios"

const instance = axios.create({
    // baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true
});

const REACT_APP_CRM_InstanceURL = 'APIsWrapperGateway/apiv1';

instance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response.status === 401) {
            // console.log("401");
            window.localStorage.clear();
            window.location.reload('/login')
        }
    }
)

export const getTokenAPI = async () => {
    return instance.get('APIsWrapperIdentity/connect/token')
}

export const updateToken = async () => {
    if (!window.localStorage.getItem('tokenCreatedDate') || window.localStorage.getItem('token') == 'undefined' || (window.localStorage.getItem('tokenCreatedDate') && window.localStorage.getItem('token') && (new Date().getTime() - window.localStorage.getItem('tokenCreatedDate'))/1000 >= 1800)) {
        console.log('updateToken')
        const rs = await getTokenAPI()
        window.localStorage.setItem('token', rs.data.access_token)
        window.localStorage.setItem('tokenCreatedDate', new Date().getTime())
    }
}
export const memberAuthenAPI = async (userInfoLogin) => {
    await updateToken();
    const MemberHeader = {
        headers: {
            "Authorization": "Bearer " + window.localStorage.getItem('token'),
            "Content-Type": "application/json",
            "SoapAction": "https://matrixapis/JSONCommand2",
        }
    }
    const MemberBody = JSON.stringify({
        "Command": "MEMBER AUTHENTICATION",
        "UserIDisCardNo": false,
        "UserID": userInfoLogin.username,
        "Password": userInfoLogin.password,
        "RetrieveBase64ImageString": false,
        "IPAddress": "",
        "CheckFirstTimeLogin": false,
        "GetToken": false
    })
    return instance.post(REACT_APP_CRM_InstanceURL, MemberBody, MemberHeader)
}

export const memberEnquiryAPI = async (email) => {
    await updateToken();
    const MemberHeader = {
        headers: {
            "Authorization": "Bearer " + window.localStorage.getItem('token'),
            "Content-Type": "application/json",
            "SoapAction": "https://matrixapis/JSONCommand2",
        }
    }
    const MemberBody = JSON.stringify({
        "Command": "MEMBER ENQUIRY",
        "Email": email,
        "FilterCardsByStatus": "ACTIVE",
        "CardLists_PageNumber": 1,
        "CardLists_PageCount": 99
    })

    return instance.post(REACT_APP_CRM_InstanceURL, MemberBody, MemberHeader)
}

export const getCustomCodesAPI = async () => {
    await updateToken();
    const MemberHeader = {
        headers: {
            "Authorization": "Bearer " + window.localStorage.getItem('token'),
            "Content-Type": "application/json",
            "SoapAction": "https://matrixapis/JSONCommand2",
        }
    }
    const MemberBody = JSON.stringify({
        "Command": "GET CUSTOM CODES",
        "ParentCode": "SpinAndWin"
    })
    return instance.post(REACT_APP_CRM_InstanceURL, MemberBody, MemberHeader)
}

export const getCustomCodesInfoAPI = async () => {
    await updateToken();
    const MemberHeader = {
        headers: {
            "Authorization": "Bearer " + window.localStorage.getItem('token'),
            "Content-Type": "application/json",
            "SoapAction": "https://matrixapis/JSONCommand2",
        }
    }
    const MemberBody = JSON.stringify({
        "Command": "GET CUSTOM CODES",
        "ParentCode": "SpinAndWinInfo"
    })
    return instance.post(REACT_APP_CRM_InstanceURL, MemberBody, MemberHeader)
}

export const getVouchersAPI = async (cardNo) => {
    await updateToken();
    const MemberHeader = {
        headers: {
            "Authorization": "Bearer " + window.localStorage.getItem('token'),
            "Content-Type": "application/json",
            "SoapAction": "https://matrixapis/JSONCommand2",
        }
    }
    const MemberBody = JSON.stringify({
        "Command": "GET VOUCHERS",
        "CardNo": cardNo,
        "RetrieveMembershipInfo": true,
        "FilterBy_VoucherType": "SWToken",
        "FilterBy_TriggerSource": "",
        "SortOrder": "ASC",
        "SortBy_VoucherNo": false,
        "SortBy_VoucherType": false,
        "SortBy_ValidFrom": false,
        "SortBy_ValidTo": true,
        "SortBy_RedeemedDate": true,
        "RetrieveActiveVouchers": true,
        "PageNumber_ActiveVouchers": 1,
        "PageCount_ActiveVouchers": 99
    })
    return instance.post(REACT_APP_CRM_InstanceURL, MemberBody, MemberHeader)
}

export const voucherRedemption = async (item,cardNo) => {
    await updateToken();
    const localDate = new Date()

    const MemberHeader = {
        headers: {
            "Authorization": "Bearer " + window.localStorage.getItem('token'),
            "Content-Type": "application/json",
            "SoapAction": "https://matrixapis/JSONCommand2",
        }
    }
    const MemberBody = JSON.stringify({
        "Command": "VOUCHER REDEMPTION",
        "CardNo": cardNo,
        "CVC": "",
        "ReceiptNo": localDate.getFullYear() + '' + ((localDate.getMonth() > 8) ? (localDate.getMonth() + 1) : ('0' + (localDate.getMonth() + 1))) + '' + (((localDate.getDate() > 9) ? localDate.getDate() : ('0' + localDate.getDate()))) + '' + localDate.getHours() + '' + localDate.getMinutes() + '' + localDate.getSeconds(),
        "TransactDate": `/Date(${localDate.getTime()})/`,
        "OriginalDate": null,
        "TransactTime": `${localDate.getHours()}:${localDate.getMinutes()}:${localDate.getSeconds()}`,
        "Remarks": "REDEMPTION Voucher: " + item.VoucherNo,
        "CheckReceiptNoDuplication": false,
        "CheckOutletCodeDuplication": true,
        "CheckOriginalDateDuplication": true,
        "CheckPOSIDDuplication": true,
        "RedemptionVoucherLists": [
            {
                "VoucherNo": item.VoucherNo
            }
        ],
        "RetrieveMembershipInfo": true,
        "RetrieveActiveVouchersList": false
    })
    return instance.post(REACT_APP_CRM_InstanceURL, MemberBody, MemberHeader)
}

export const rewardCampaign = async (item, isStoreHistory, cardNo) => {
    await updateToken();
    const MemberHeader = {
        headers: {
            "Authorization": "Bearer " + window.localStorage.getItem('token'),
            "Content-Type": "application/json",
            "SoapAction": "https://matrixapis/JSONCommand2",
        }
    }
    const MemberBody = JSON.stringify({
        "Command": "REWARD CAMPAIGN",
        "CardNo": cardNo,
        "AmtToCalculateAR": 0,
        "CampaignProcessingDate": null,
        "CampaignType": "Reward Campaign",
        "CampaignCode": item,
        "CheckQualificationRules": true,
        "RetrieveMembershipInfo": true,
        "RetrieveIssuedVoucherLists": false,
        "RetrieveActiveVouchersLists": false,
        "PageNumber": 1,
        "PageCount": 99,
        "Remarks": "CAMPAIGN Code: " + item,
        "CheckStorePrize": isStoreHistory
    })
    return instance.post(REACT_APP_CRM_InstanceURL, MemberBody, MemberHeader)
}
