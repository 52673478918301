export const sortDesc = (vouches) => {
    const listItem = vouches.ActiveVoucherLists
    if (listItem) {
        for (var i = 0; i < listItem.length - 1; i++) {
            for (var j = i + 1; j < listItem.length; j++) {
                var firtNum = convertToNumber(listItem[i].ValidTo)
                var secondNum = convertToNumber(listItem[j].ValidTo)
                if (firtNum > secondNum) {
                    var temp = listItem[i]
                    listItem[i] = listItem[j]
                    listItem[j] = temp
                }
            }
        }
    }
    vouches.ActiveVoucherLists = listItem
    return vouches
}

function convertToNumber(item) {
    item = item.substring(6, item.length - 2);
    return parseInt(item)
}