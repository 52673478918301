import React, { Component } from 'react';
import { connect } from 'react-redux';
import SpinRound from '../../../src/assets/images/Round.png';
import SpinIcon from '../../../src/assets/images/SpinIcon.png';
import './Home.scss';
import * as API from '../../services'
import PopUp from '../../components/PopUp/PopUp'
import 'reactjs-popup/dist/index.css'
import { sortDesc } from '../../common/sortDateDESC';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PopUpState: false,
            popUpLoginState: false,
            PopUpStatus: '',
            styleSpinWheel: {},
            voucher: {},
            codes: [],
            rewardCampaignHistory: {},
            prize: "",
            rewardPrize: {},
            code: undefined,
            timeInfo: "",
            descriptionInfo: "",
            checkSpin: false,
            memberCardNo: "",
            description: "",
            emptyRewards: false,
        }
        this.btnSpin = React.createRef()
        this.handleSpin = this.handleSpin.bind(this);
    }

    handleSpin(random) {
        const styleSheets = document.styleSheets[document.styleSheets.length - 1];
        const nameAni = `spin-wheel-${Math.round(Math.random() * 180)}`;
        const keyFrames = `@-webkit-keyframes ${nameAni} {
            from {transform: rotate(0deg)}
            to {transform: rotate(360deg)}
        }`
        styleSheets.insertRule(keyFrames, styleSheets.cssRules.length);
        const styleData = {
            animationName: nameAni,
            animationTimingFunction: 'linear',
            animationDuration: '1.3s',
            animationIterationCount: 'infinite'
        }
        this.setState({ styleSpinWheel: styleData });
        setTimeout(() => {
            const styleSheets = document.styleSheets[document.styleSheets.length - 1];
            styleSheets.removeRule(styleSheets.cssRules.length - 1);
            const rotate = 5 * 360 + (-360 * random / 5) + 25;
            const stopWheel = {
                transform: `rotate(${rotate}deg)`,
                transition: `transform 3s`
            }
            this.setState({
                styleSpinWheel: stopWheel,
            });
        }, 3000)
    }

    componentDidMount = async () => {
        const query = new URLSearchParams(window.location.search);
        let tokenLocal = query.get('access_token')
        if(!tokenLocal) {
            let domain = (new URL(process.env.REACT_APP_LEVIS_PROFILE));
            domain = domain.hostname;
            window.location.href = `https://${domain}/Registration/Login.aspx?returnurl=${process.env.REACT_APP_BACKEND_URL}`;
            return;
        }
        tokenLocal = await this.decodeEmail(tokenLocal);
        const member = await API.memberEnquiryAPI(tokenLocal);
        let memberCardNo = '';
        if (member.data && member.data.CardLists && member.data.CardLists.length > 0) {
            memberCardNo = member.data.CardLists[0].CardNo;
            const codesAPI = await API.getCustomCodesAPI()
            const voucherAPI = await API.getVouchersAPI(memberCardNo)
            const newVouchers = sortDesc(voucherAPI.data)
            this.setState({
                voucher: newVouchers,
                codes: codesAPI.data.CustomCodes,
                time: codesAPI.data.CustomCodes[0].Description,
                description: codesAPI.data.CustomCodes[1].Description,
                memberCardNo: memberCardNo,
            });
        } else {
            this.setState({
                popUpLoginState: true
            })
        }
    }

    decodeEmail = async (encodedString) => {
        var email = "";    
        var keyInHex = encodedString.substr(0, 2);
        var key = parseInt(keyInHex, 16);
    
        for (var n = 2; n < encodedString.length; n += 2) {
    
            var charInHex = encodedString.substr(n, 2)
            var char = parseInt(charInHex, 16);
            var output = char ^ key;
            email += String.fromCharCode(output);
        }
        return email;
    }

    handlePrize = async () => {
        const listPrizeTemp = [];
        this.state.codes.forEach(item => {
            const historyValue = this.state.rewardCampaignHistory[item.CustomCode]
            if (!historyValue
                || (historyValue && historyValue === 99999) || (historyValue && historyValue < item.Description)) {
                listPrizeTemp.push(item);
            }
        });
        if (listPrizeTemp.length > 0) {
            const ramdomTemp = Math.floor(Math.random() * listPrizeTemp.length);
            const prize = listPrizeTemp[ramdomTemp];
            const positionPrize = this.state.codes.findIndex(x => x.CustomCode === prize.CustomCode) + 1;
            this.setState({ rewardPrize: prize })
            this.handleSpin(positionPrize)
            this.setState({ emptyRewards: false})
        } else {
            this.setState({ rewardPrize: undefined })
            this.setState({ emptyRewards: true})
        }
    }

    updateVoucherActive = async () => {
        const voucherAPI = await API.getVouchersAPI(this.state.memberCardNo)
        const listPrize = await API.getCustomCodesAPI()
        const newVouchers = sortDesc(voucherAPI.data)
        this.setState({
            voucher: newVouchers,
            codes: listPrize.data.CustomCodes,
            rewardCampaignHistory: listPrize.data.RewardCampaignHistory,
        })
    }

    handleClick = async () => {
        if (!this.state.checkSpin) {
            this.setState({
                checkSpin: true
            })
            await this.updateVoucherActive();
            if (this.state.voucher.TotalActiveVoucherCount > 0) {
                await this.handlePrize();
                if (this.state.rewardPrize) {
                    const prizeAPI = await API.rewardCampaign(this.state.rewardPrize.CustomCode, this.state.rewardPrize.Description !== 99999, this.state.memberCardNo)
    
                    let rewardStatus = '';
                    if (prizeAPI.data.TransactionReferenceInfo) {
                        rewardStatus = 'SUCCESS'
                        this.setState({ prize: prizeAPI.data.TransactionReferenceInfo.CampaignName })
                        // await API.voucherRedemption(this.state.voucher.ActiveVoucherLists[0], this.state.memberCardNo)
                        const voucherAPI = await API.getVouchersAPI(this.state.memberCardNo)
                        const newVouchers = sortDesc(voucherAPI.data)
                        this.setState({
                            voucher: newVouchers
                        })
                    } else if (prizeAPI.data.ReturnStatus === 7) {
                        rewardStatus = 'INACTIVE'
                    }
        
                    setTimeout(() => {
                        this.setState({
                            PopUpState: true,
                            PopUpStatus: rewardStatus,
                            checkSpin: false
                        })
                    }, 1000)
                } else {
                    if (this.state.emptyRewards) {
                        this.setState({
                            PopUpState: true,
                            checkSpin: false,
                            PopUpStatus: 'INACTIVE'
                        })
                    }
                }
            }
        }
    }

    render() {
        const { styleSpinWheel } = this.state;
        return (
            <div className="home-wrapper">
                {this.state.PopUpState && <PopUp statusMessage={this.state.PopUpStatus} chances={this.state.voucher.TotalActiveVoucherCount}
                    item={this.state.rewardPrize.Name}
                    onClick={() => this.setState({ PopUpState: false })} />}
                {this.state.popUpLoginState && <PopUp statusMessage={this.state.PopUpStatus}
                    onClick={() => this.setState({ popUpLoginState: false })} />}
                <div className="home-container">
                    <div className="bg-container">
                        <div className="round">
                            <div className="icon-round-box">
                                <div className="box-round-img list-voucher" style={styleSpinWheel} >
                                    <img src={SpinRound} alt="" className="icon-round" />
                                    <div className="voucher-box" style={{}} onClick={this.handleClick}>
                                        {
                                            this.state.codes && this.state.codes.map((item, index) => {
                                                let name = item.Name + "";
                                                let nameWithouVoucher = name.substring(0, name.indexOf("voucher"));
                                                let voucher = name.substring(name.indexOf("voucher"), name.length);
                                                 return (
                                                    <li key={index} className="voucher-item">
                                                        <p className='text-no-voucher'>{nameWithouVoucher}</p>
                                                        <p className='text-voucher'>{voucher}</p>
                                                    </li>)
                                                })
                                        }
                                
                                    </div>
                                </div>
                                <div ref={this.btnSpin} className="spin" onClick={this.handleClick}>
                                    <img src={SpinIcon} alt="" className="icon-spin" />
                                </div>
                            </div>
                        </div>

                        <div className="content">
                            <p className="title">Spin & win</p>
                            <p className="description">
                                Spin the wheel to win Levi's® vouchers worth up to P500,<br />redeemable with a minimum spend of P5,000.<br /><br />
                                Tap on the wheel to spin for your voucher. You’ll have 2 spins to play from now until 31 December 2022.<br />
                                It’s a sure-win and vouchers will be automatically added to your profile!
                            </p>
                            <p className="chances">Spins left: {this.state.voucher.TotalActiveVoucherCount || 0}</p>
                        </div>
                    </div>

                    <div className="footer-container">
                        <div className="terms">
                            <p className="terms-title">Terms and conditions:</p>
                            <p className="terms-content">
                            - Open to all new and existing Levi's® Red Tab™ Program members. <br/>
                            - All members will be given a maximum of 2 spins, playable from 1–31 December 2022. No more spins will be given once the maximum is reached and/or beyond the campaign validity period.  <br/>
                            - Voucher(s) won will be automatically added to your member profile on levi.com.ph, valid on purchases made on levi.com.ph and at Levi's® Boutique Stores. <br/>
                            - All vouchers are redeemable with a minimum spend of P5,000 on regular priced items only, and valid until 31 December 2022. <br/>
                            - Whilst vouchers last.<br/>
                            <br/>
                            DTI Fair Trade Permit No. FTEB-156298 Series of 2022
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.admin.isLoggedIn
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
