import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from "connected-react-router";
import * as actions from "../../store/actions";
import { KeyCodeUtils } from "../../utils";
import userIcon from '../../../src/assets/images/user.svg';
import passIcon from '../../../src/assets/images/pass.svg';
import 'font-awesome/css/font-awesome.min.css';
import './Login.scss';
import * as API from '../../services'

class Login extends Component {
    constructor(props) {
        super(props);
        this.btnLogin = React.createRef();
    }

    initialState = {
        username: '',
        password: '',
        load: false
    }

    state = {
        ...this.initialState
    };

    refresh = () => {
        this.setState({
            ...this.initialState
        })
    }

    onUsernameChange = (e) => {
        this.setState({ username: e.target.value })
    }

    onPasswordChange = (e) => {
        this.setState({ password: e.target.value })
    }

    redirectToSystemPage = () => {
        const { navigate } = this.props;
        const redirectPath = '/';
        navigate(`${redirectPath}`);
    }

    handleLogin = async (res) => {
        const rs = await API.getTokenAPI()
        window.localStorage.setItem('token', rs.data.access_token)
        window.localStorage.setItem('tokenCreatedDate', new Date().getTime())

        const userInfoLogin = {
            username: res.username,
            password: res.password
        }
        const { adminLoginSuccess, adminLoginFail } = this.props;
        const ex = await API.memberAuthenAPI(userInfoLogin)

        if (ex.data.IsValid) {
            let memberInfo = {
                MobileNo: ex.data.MemberInfo.MobileNo,
                CardNo: ex.data.MembershipCardLists[0].CardNo
            }

            window.localStorage.setItem('MobileNo', ex.data.MemberInfo.MobileNo);
            window.localStorage.setItem('CardNo', ex.data.MembershipCardLists[0].CardNo);
            adminLoginSuccess(memberInfo);
        } else {
            adminLoginFail()
            window.localStorage.setItem('loginError', ex.data.ReturnMessage)
        }

        this.redirectToSystemPage();
    }

    processLogin = async () => {

        const rs = await API.getTokenAPI()
        window.localStorage.setItem('token', rs.data.access_token)
        window.localStorage.setItem('tokenCreatedDate', new Date().getTime())

        const userInfoLogin = {
            username: this.state.username,
            password: this.state.password
        }
        const { adminLoginSuccess, adminLoginFail } = this.props;
        const ex = await API.memberAuthenAPI(userInfoLogin)

        if (ex.data.IsValid) {
            let memberInfo = {
                MobileNo: ex.data.MemberInfo.MobileNo,
                CardNo: ex.data.MembershipCardLists[0].CardNo
            }

            window.localStorage.setItem('MobileNo', ex.data.MemberInfo.MobileNo);
            window.localStorage.setItem('CardNo', ex.data.MembershipCardLists[0].CardNo);
            adminLoginSuccess(memberInfo);
        } else if (ex.data.ReturnMessage && ex.data.ReturnMessage === 'SUCCESS'){
            adminLoginFail()
            window.localStorage.setItem('loginError', 'Incorrect username or password')
        } else {
            adminLoginFail()
            window.localStorage.setItem('loginError', ex.data.ReturnMessage)
        }

        this.redirectToSystemPage();
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.btnLogin.current || this.btnLogin.current.disabled) return;
            this.btnLogin.current.click();
        }
    };

    componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        const tokenLocal = query.get('access_token')
        console.log('componentDidMount', tokenLocal)
        if (tokenLocal) {
            // window.localStorage.setItem('tokenLocal', tokenLocal)
            // const res = jwt(tokenLocal)
            // this.handleLogin(res)
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handlerKeyDown);
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }


    render() {
        const { username, password } = this.state;
        return (
            <div className="login-wrapper">
                <div className="login-container">
                    <div className="title-panel">
                        <h2 className="title">
                            Member Sign In
                        </h2>
                    </div>
                    <div className="form_login" style={
                        window.localStorage.getItem('loginError')
                            ? { height: "445px" }
                            : { height: "375px" }}>

                        {window.localStorage.getItem('loginError') &&
                            <div className='login-error'>
                                <span className='login-error-message'>{window.localStorage.getItem('loginError')}</span>
                            </div>
                        }

                        <div className="form-group icon-true">
                            <img className="icon" src={userIcon} alt="this" />
                            <input
                                placeholder="Username"
                                id="username"
                                name="username"
                                type="text"
                                className="form-control"
                                value={username}
                                onChange={this.onUsernameChange}
                            />
                        </div>

                        <div id="phone-input-container" className="form-group icon-true">
                            <img className="icon" src={passIcon} alt="this" />
                            <input
                                placeholder="Password"
                                id="password"
                                name="password"
                                type="password"
                                className="form-control"
                                value={password}
                                onChange={this.onPasswordChange}
                            />
                        </div>

                        <div className="form-group">
                            Default Username: Mobile Number or Email provided during account registration
                            <br />
                            Default Password: Date of Birth (DDMMYYYY)
                        </div>

                        <div className="form-group login">
                            <button
                                ref={this.btnLogin}
                                id="btnLogin"
                                type="button"
                                className="btn"
                                disabled={this.state.load}
                                onClick={() => (
                                    this.setState({
                                        load: true
                                    }),
                                    this.processLogin().then(() => this.setState({ load: false }))
                                )}
                            >
                                {this.state.load ? <i className='fa fa-refresh fa-spin'></i> : "LOGIN"}
                            </button>
                        </div>

                        <div>
                            <hr className='forgot-password'></hr>
                            <a href='https://www.crystaljade.com/membership/site/forgot' target="_blank" rel="noreferrer" className='form-pass'>Forgot Password?</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.app.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        adminLoginSuccess: (memberInfo) => dispatch(actions.adminLoginSuccess(memberInfo)),
        adminLoginFail: () => dispatch(actions.adminLoginFail()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
